.block6 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--primary-color);
  padding: 20px;
}

.block6-left{
  width: 70%;
}

.block6-right{
  flex-basis: 25%;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block6-right .image-container img{
  width: 100%;
  max-width: 200px;
}

@media (max-width: 1000px) {
  .block6-left{
    width: 100%;
  }
  
}