.block4 {
  display: flex;
  /* flex: 1; */
  flex-wrap: wrap;
  background-color: #e6e7fe;
  padding: 0px 10px;
  /* width: 100%; */
}

.block4-left {
  display: flex;
  flex-basis: 70%;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 300px;
}

.block4-text {
  font-size: 25px;
  /* margin-right: 30px; */
}

.block4-right {
  /* border: 5px solid var(--quaternary-color); */
  display: flex;
  flex-basis: 30%;
  justify-content: center;
  align-items: center;
  /* min-width: 400px; */
  /* transform: rotate(-0deg); */
}

/* .block4-right .image-container{
  margin: auto;
} */

.block4-right .image-container img{
  width: 100%;
  max-width: 300px;
}

@media (max-width: 800px) {
  .block4 {
    width: 100%;
  }

  .block4-left {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
  }

  .block4-right {
   display: flex;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
  }
}

