.diretor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #ecf8f2;
}

.diretor-title {
  font-size: 40px;
  text-shadow: 2px 2px 0 var(--quaternary-color);
  text-align: center;
}

.diretor-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.diretor-left {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 30%; /* Padrão para telas menores */
  text-align: center;
  padding: 20px;
}

.diretor-right {
  flex-basis: 60%; /* Padrão para telas menores */
  padding: 20px;
}

.diretor-right ul{
  font-size: 30px;
  line-height: 2.0;
}
.diretor .image-container {
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;

}

.foto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Layout para telas maiores que 700px */
@media (max-width: 1000px) {
  .diretor-content {
    flex-direction: row;
  }

  .diretor-left {
    flex-basis: 100%;  
  }

  .diretor-right {
    flex-basis: 100%; 
  }
}
/* 
@media (max-width: 700px) {
  .diretor-left,
  .diretor-right {
    flex-basis: 100%; 
}

@media (max-width: 500px) {
  .diretor-title {
    font-size: 45px;
    padding-top: 50px;
  }

  .fotojulio {
    max-width: 300px;
  }
} */