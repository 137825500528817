.agenda {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  padding: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.agenda-left, .agenda-right {
  /* flex: 1 1 50%; */
  min-width: 350px;
  padding: 20px;
}

.agenda-left {
  color: var(--secondary-color);
}

.agenda-right {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.agenda-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--primary-color);
}

.agenda-list {
  list-style: none;
  padding: 0;
  color: black;
}

.agenda-list li {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 1.5;
}

.agenda-date {
  font-weight: bold;
  color: var(--secondary-color);
}

.agenda-left .image-container {
  height: 200px; 
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  position: relative;
}

.agenda-left .image-container img {
  width: 100%;
  height: auto;
  position: relative; 
  top: -100px; 
}

/* Responsividade */
@media (max-width: 768px) {
  .agenda {
    flex-direction: column;
  }

  .agenda-left, .agenda-right {
    flex: 1 1 100%;
    text-align: center;
    padding: 10px;
  }

  .agenda-left .image-container {
    margin: 0 auto;
  }

  .agenda-right {
    padding: 20px;
  }
}
