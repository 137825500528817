/* Join Manifest styles */
.join-manifest-section {
  background-color: #fff5f5; /* Light red tone background */
  padding: 20px 20px;
  /* margin: 20px auto; */
  /* max-width: 800px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.join-manifest-title {
  color: #b22222; /* Firebrick red */
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.join-manifest-text {
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 20px;
}

.join-manifest-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  width: 100%;
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
}

input::placeholder {
  color: black; /* Your desired color */
  font-size: 15px;
}

.submit-button {
  background-color: #b22222; /* Firebrick red */
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #8b0000; /* Dark red */
}
