.block7 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* width: 100%; */
  background-color: var(--secondary-color);
  padding: 0px 10px;;
}

.block7-left {
  width: 30%;
  min-width: 300px;
}

.block7-right {
  width: 60%;
  /* min-width: 400px; */
}

.block7-left img {
  width: 100%;
}

@media (max-width: 1000px) {
  
  .block7-right {
    /* margin: auto; */
    text-align: center;
    width: 100%;
    /* margin-right: 30px; */
  }
}
