.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: var(--secondary-color);
  color: #fff;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between; /* Ensure space between logo and hamburger */
  align-items: center;
  z-index: 10;
  box-sizing: border-box; 
  
}

.logo h1 {
  margin: 0 30px;
  font-size: 1.5rem;
  color: #fff;
}

/* Hamburger button */
.hamburger {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-right: 30px; /* Add margin to align it to the right */
}

/* Navbar styles */
.navbar {
  display: flex;
  justify-content: flex-end; /* Align the full menu to the right */
  flex-grow: 1; /* Allow the navbar to take available space */
  margin-right: 30px;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu-item {
  margin-left: 20px;
}

.menu-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.menu-item a:hover {
  background-color: #8b0000; /* Dark red */
}

/* Responsive styles */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    justify-content: space-between; /* Ensure the logo stays on the left and hamburger on the right */
    align-items: center;
  }

  .hamburger {
    display: block; /* Display hamburger in mobile view */
  }

  .navbar {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .navbar.open {
    display: flex;
  }

  .menu {
    flex-direction: column;
    width: 100%;
  }

  .menu-item {
    margin: 10px 0;
  }

  .menu-item a {
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
  }
}
