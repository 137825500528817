:root {
  --primary-color: #ecf8f2;
  --secondary-color: #660034;
  --terteary-color: #2b2b2b;
  --quaternary-color: #135736;
  --quinary-color: #facc6e;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.barrio {
  font-family: "Barrio", system-ui;
  font-weight: 400;
  font-style: normal;
}

.f50 {
  font-size: 50px;
}

.f80 {
  font-size: 80px;
}

.primary-color {
  color: var(--primary-color)
}

.secondary-color {
  color: var(--secondary-color);
}

.quaternary-color {
  color: var(--quaternary-color)
}

.text-center {
  text-align: center;
}

.text-white {
  color: white;
}