/* Footer styles */
.footer {
  background-color: var(--primary-color); /* Firebrick red */
  color: var(--secondary-color);
  /* text-align: center; */
  /* padding: 20px; */
  margin-top: 10px;
}

.footer-line1 {
  display: flex;
  border-top: 1px solid var(--secondary-color);
}

.footer-left{
  border-right: 1px solid var(--secondary-color);
  padding: 20px;
}

.footer-right {
  /* background-color: red; */
  display: flex;
  align-items: center;
  /* width: 100%; */
}

.footer p {
  margin: 5px 0;
  font-size: 0.9rem;
}

.cc-link {
  color: var(--secondary-color);
  text-decoration: underline;
}

.cc-link:hover {
  color: #8b0000; /* Dark red */
}

.social-icons {
  margin-top: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.social-link {
  color: #fff;
  font-size: 1.5rem;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #8b0000; /* Dark red */
}

.footer-line2 {
  text-align: center;
  /* width: 100%; */
  background-color: #ccc;
  padding: 10px;
  /* margin-top: 25px 0px 0px 0px; */
}
