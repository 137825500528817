/* Suggestion Form styles */
.suggestion-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background-color: var(--secondary-color); */
  background-color: #c58cdd;
  padding: 40px 20px;
  /* max-width: 600px; */
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.suggestion-form-left, .suggestion-form-right{
  flex: 1;
}

.suggestion-form .image-container {
  margin: auto;
}

.suggestion-form .image-container img {
  width: 400px;
}

.suggestion-form-right {
  /* width: 100%; */
  padding: 20px;
}

.form-title {
  color: #b22222;
  /* Firebrick red */
  text-align: center;
  margin-bottom: 20px;
}

.suggestion-form .form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: white;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea.form-input {
  resize: vertical;
}

.submit-button {
  background-color: white;
  /* Firebrick red */
  color: var(--secondary-color);
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.submit-button:hover {
  background-color: #ccc;
}

.suggestion-form .image-container {
  width: 400px;
}