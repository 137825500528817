/* Manifest styles */
.manifest {
  padding: 20px 20px;
  margin: 0px auto;
  /* max-width: 800px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--secondary-color);
}

.manifest-title {
  color: white; /* Firebrick red */
  font-size: 8rem;
  margin: 0px;
  text-align: center;
  text-shadow:
    2px 2px 0 var(--quaternary-color),
    /* -1px 1px 0 var(--quaternary-color), */
    /* 1px -1px 0 var(--quaternary-color), */
    -0px -0px 0 var(--quaternary-color);
}

.manifest-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.manifest .image-container{
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 20%;
  /* min-width: 500px; */
}

.manifest .text-container{
  width: 70%
}

.manifest-text {
  /* width: 40%; */
  color: white;
  font-size: 1.5rem;
  line-height: 1.3;
  text-align: center;
}

.manifest .image-container img{
  width: 100%;
}

.more-content {
  display: inline;
}

.read-more-button {
  background-color: var(--quaternary-color);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.read-more-button:hover {
  /* background-color: #2980b9; */
}

@media (max-width: 1000px) {
  .manifest-title{
    font-size: 60px;
  }

  .manifest .text-container{
    width: 100%
  }
}


@media (max-width: 600px) {
  .manifest{
    width: 100%;
  }

  .manifest-title{
    font-size: 50px;
  }
}

