.banner {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* width: 100%; */
  padding: 40px 20px;
  background-color: #ecf8f2;
  /* border: 1px solid black; */
}

.banner-title {
  font-size: 80px;
  line-height: 0.0;
  text-shadow: 2px 2px 0 var(--quaternary-color);
}

.banner-left,
.banner-right {
  flex: 1;
  min-width: 350px;
  /* background-color: orange; */
}

.banner-left {
  padding-left: 20px;
}

/* .image-container {
  text-align: center;
} */

.roda {
  max-width: 600px;
  width: 80%;
  height: auto;
}

@media (max-width: 768px) {

  .banner-left,
  .banner-right {
    width: 100%;
    text-align: center;
  }

  .banner-title {
    font-size: 60px;
  }

  .roda {
    max-width: 500px;
  }
}

@media (max-width: 500px) {
  .banner {
    width: 100%;
  }

  .banner-title {
    font-size: 50px;
    padding-top: 50px;
  }

  .roda {
    max-width: 300px;
  }
}