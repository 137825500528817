.block5 {
  background-color: var(--secondary-color);
  padding: 10px 10px;
}

.block5 .section-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* border: 1px solid white; */
}

.block5-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-width: 400px;
  /* border: 1px solid white; */

}

.block5 .section-2 {
  font-size: 28x;
  padding: 0px 20px;
}

.block5-left .image-container img{
  width: 100%;
  min-width: 300px;
}

.block5-right {
  /* border: 1px solid white; */
  /* min-width: 400px; */
  width: 60%;
}

/* .block5 img{
  width: 100%;
} */

@media (max-width: 800px) {
  .block5{
    width: 100%;
  }
}
