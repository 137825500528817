.block8 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* width: 100%; */
  background-color: white;
  padding: 0px 10px;
  font-size: 40px;
}

.block8 a {
  text-decoration: none;
  color: black;
}
